import React from 'react';
import { FragmentType, graphql, parseFragmentData } from '../../../../../graphql/generated';
import { MedelyDateTime } from '@medely/date-time-tools';
import { pluralize } from '../../../../../utils';
import { Box, Card, HStack, Icon, InfoBadge, Label, Text, VStack } from '@medely/ui-kit';
import { EditWorkExperienceModal } from '../editWorkExperienceModal/EditWorkExperienceModal';
import useDialog from '../../../../../hooks/useDialog';
import { AddExperienceModal } from '../workExperienceModal/AddExperienceModal';

const ExperienceSummaryCardWorkHistoryGql = graphql(`
  fragment ExperienceSummaryCardWorkHistory on WorkHistory {
    created_at
    currently_work_here
    end_date
    id
    start_date
  }
`);

const ExperienceSummaryCardPositionGql = graphql(`
  fragment ExperienceSummaryCardPosition on Position {
    id
    display_name
    incrementality(market_ids: $market_ids)
  }
`);

interface Props {
  position: FragmentType<typeof ExperienceSummaryCardPositionGql>;
  workHistories?: FragmentType<typeof ExperienceSummaryCardWorkHistoryGql>[];
}

const getWorkHistoryInfo = (
  inputWorkHistories?: FragmentType<typeof ExperienceSummaryCardWorkHistoryGql>[],
) => {
  const workHistories =
    inputWorkHistories?.map((workHistory) =>
      parseFragmentData(ExperienceSummaryCardWorkHistoryGql, workHistory),
    ) || [];

  if (!workHistories.length) {
    return {
      hasWorkHistory: false,
    };
  }

  let mostRecentWorkHistoryTimeStamp = workHistories[0].created_at;
  let mostRecentWorkHistoryId = workHistories[0].id;

  workHistories?.forEach((workHistory) => {
    if (
      new MedelyDateTime(workHistory.created_at, { tz: 'system' }).isAfter(
        new MedelyDateTime(mostRecentWorkHistoryTimeStamp, { tz: 'system' }),
      )
    ) {
      mostRecentWorkHistoryTimeStamp = workHistory.created_at;
      mostRecentWorkHistoryId = workHistory.id;
    }
  });

  let earliestStartDate = new MedelyDateTime(workHistories[0].start_date, { tz: 'system' });
  const firstEndsDate = workHistories[0].end_date;
  let latestEndDate = firstEndsDate
    ? new MedelyDateTime(firstEndsDate, { tz: 'system' })
    : MedelyDateTime.now({ tz: 'system' });

  workHistories.forEach((workHistory) => {
    const startDate = new MedelyDateTime(workHistory.start_date, { tz: 'system' });
    const endDate = workHistory.end_date
      ? new MedelyDateTime(workHistory.end_date, { tz: 'system' })
      : MedelyDateTime.now({ tz: 'system' });

    if (!earliestStartDate || startDate.isBefore(earliestStartDate)) {
      earliestStartDate = startDate;
    }

    if (!latestEndDate || endDate.isAfter(latestEndDate)) {
      latestEndDate = endDate;
    }
  });

  const timesWorked = workHistories.reduce((acc, workHistory) => {
    const startDate = new MedelyDateTime(workHistory?.start_date, { tz: 'system' });

    const endDate = workHistory?.end_date
      ? new MedelyDateTime(workHistory.end_date, { tz: 'system' })
      : MedelyDateTime.now({ tz: 'system' });
    const diff = startDate.difference(endDate, 'months');
    return acc + diff;
  }, 0);

  const months = timesWorked % 12;
  const years = (timesWorked - months) / 12;
  const timeWorked = `${years ? `${pluralize(years, 'yr', 's', '')} ` : ''}${
    months ? `${pluralize(months, 'mo', 's', '')}` : ''
  }`;

  const datesWorkedStart = earliestStartDate?.toLocaleString({
    month: 'short',
    year: 'numeric',
  });

  const datesWorkedEnd =
    !latestEndDate || latestEndDate.difference(MedelyDateTime.now({ tz: 'system' }), 'days') === 0
      ? 'Current'
      : latestEndDate.toLocaleString({
          month: 'short',
          year: 'numeric',
        });

  const datesWorked = `${datesWorkedStart} - ${datesWorkedEnd}`;

  return {
    datesWorked,
    hasWorkHistory: true,
    mostRecentWorkHistoryId,
    timeWorked,
  };
};

export const ExperienceSummaryCard = ({
  position: inputPosition,
  workHistories: inputWorkHistory,
}: Props) => {
  const editModal = useDialog();
  const addModal = useDialog();
  const position = parseFragmentData(ExperienceSummaryCardPositionGql, inputPosition);
  const isHighDemand = position.incrementality === 'prioritize';

  const { datesWorked, hasWorkHistory, mostRecentWorkHistoryId, timeWorked } =
    getWorkHistoryInfo(inputWorkHistory);

  const handleEditClick = () => {
    mostRecentWorkHistoryId ? editModal.openDialog() : addModal.openDialog();
  };

  return (
    <Card>
      <HStack alignItems="center" justifyContent="space-between">
        <VStack>
          {hasWorkHistory && (
            <Label
              color="state.teal.secondary"
              size="xs"
              testId={`experience-summary-card-time-worked-${position.id}`}
            >
              {timeWorked}
            </Label>
          )}
          <Label size="m" testId={`experience-summary-card-title-${position.id}`}>
            {position.display_name}
          </Label>
          {hasWorkHistory && (
            <Text size="m" testId={`experience-summary-card-dates-worked-${position.id}`}>
              {datesWorked}
            </Text>
          )}
          <Box mt={1}>
            <HStack gap={0.5}>
              {!hasWorkHistory && (
                <InfoBadge
                  label="Add details"
                  testId={`experience-summary-card-add-details-${position.id}`}
                />
              )}
              {isHighDemand && (
                <InfoBadge
                  label="High demand"
                  color="success"
                  testId={`experience-summary-card-high-demand-${position.id}`}
                />
              )}
            </HStack>
          </Box>
        </VStack>
        <Box
          onClick={() => handleEditClick()}
          testId={`experience-summary-card-edit-clickable-${position.id}`}
        >
          <Icon name="edit" />
        </Box>
        <EditWorkExperienceModal
          open={editModal.open}
          onClose={editModal.closeDialog}
          workHistoryId={mostRecentWorkHistoryId}
        />
        <AddExperienceModal open={addModal.open} onClose={addModal.closeDialog} />
      </HStack>
    </Card>
  );
};
