export const STATUS_MAP = {
  approved: {
    label: 'Active',
    color: 'text.secondary',
    badge: 'success',
  },
  pending: {
    label: 'Incomplete',
    color: 'text.secondary',
    badge: 'default',
  },
  review: {
    label: 'Under Review',
    color: 'info.main',
    badge: 'info',
  },
  rejected: {
    label: 'Rejected',
    color: 'error.main',
    badge: 'error',
  },
  expired: {
    label: 'Expired',
    color: 'error.main',
    badge: 'error',
  },
  incomplete: {
    label: 'Incomplete',
    color: 'text.secondary',
    badge: 'default',
  },
  missing: {
    label: 'Missing',
    color: 'error.main',
    badge: 'error',
  },
  error: {
    label: 'Credential Status is Missing',
    color: 'error.main',
    badge: 'error',
  },
  loading: {
    label: 'Loading',
    color: 'text.secondary',
    badge: 'loading',
  },
  expiresSoon: {
    label: 'Expires Soon',
    color: 'warning.main',
    badge: 'warning',
  },
};
