import React, { useState } from 'react';
import moment, { Moment } from 'moment';

import { Dialog, MonthRangePicker } from '@medely/web-components';
import { Button, Icon } from '@medely/ui-kit';
import { DownloadCsvFunction } from '../EarningsLayout/interface';

type EarningsExportButtonProps = {
  downloadCsvFile: DownloadCsvFunction;
};

export const EarningsExportButton = ({ downloadCsvFile }: EarningsExportButtonProps) => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<Moment[]>([moment(), moment()]);

  const onSubmit = async () => {
    const from = dateRange[0].startOf('month').format('YYYY-MM-DD');
    const to = dateRange[1].endOf('month').format('YYYY-MM-DD');

    const url = `/v3/professional/transactions?from=${from}&to=${to}`;
    const fileName = `transactions-${from}-${to}.csv`;

    await downloadCsvFile(url, fileName);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        startIcon={
          <Icon name="arrow-up-from-bracket" color="secondary" iconSize="14px" boxSize="18px" />
        }
        testId="earnings-export-button"
      >
        Export
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        title="Export payout history"
        content={
          <MonthRangePicker dateRange={dateRange} onChange={setDateRange} fullWidth={true} />
        }
        data-testid="earnings-history-modal"
        actions={
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={!dateRange[0] || !dateRange[1]}
              fullWidth
              testId="earnings-history-modal-download-button"
            >
              Download
            </Button>
          </>
        }
      />
    </>
  );
};
