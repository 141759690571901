import React, { ReactElement, ReactNode } from 'react';
import { Box, HStack, Label } from '@medely/ui-kit';

interface IJobDetailsListItemProps {
  children?: ReactNode;
  icon?: ReactElement;
  title?: string;
}

export const JobDetailsListItem = ({
  children,
  icon,
  title,
}: IJobDetailsListItemProps): ReactElement => {
  return (
    <HStack testId="job-details-list-item">
      <Box pr={icon ? 2 : 0}>{icon}</Box>
      <Box flex={1}>
        {title && <Label size="l">{title}</Label>}
        {children}
      </Box>
    </HStack>
  );
};
