import { IAccount } from '@medely/types';
import { Professional } from 'graphql/generated/graphql';
import { useConfigurationContext } from '@medely/ui-kit';
import { useQuery } from '@tanstack/react-query';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { StatsigProvider, StatsigUser, Statsig } from 'statsig-react';
import gqlRequest from 'utils/networkRequests/gqlRequest';
import { gql } from 'graphql-request';
import { useFirebaseContext } from '@medely/ui-kit/web';

interface IContext {
  isLoading: boolean;
  statsigUser?: StatsigUser;
  setCustomAttributes?: (
    customAttributes: Record<string, string | number | boolean | string[]>,
  ) => void;
}

const FeatureGateContext = createContext({
  isLoading: true,
  statsigUser: undefined,
  setCustomAttributes: undefined,
});

const CurrentUserQuery = gql`
  query GetCurrentUserForStatsig {
    me {
      company_id
      email
      id
      professional {
        id
        split_pay_phase
      }
    }
  }
`;

type CurrentUser = Pick<IAccount, 'company_id' | 'email' | 'id'> & {
  professional: Pick<Professional, 'id' | 'split_pay_phase'>;
};

const useCurrentUserForStatsig = () => {
  const { googleUser } = useFirebaseContext();

  const { data, ...props } = useQuery<CurrentUser>(
    ['GetCurrentUserForStatsig'],
    async (): Promise<CurrentUser> => {
      const response = await gqlRequest(CurrentUserQuery);
      return response?.me;
    },
    {
      enabled: !!googleUser,
    },
  );
  return { ...props, currentUser: data, isLoading: props.isLoading && !!googleUser };
};

export const FeatureGateProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const [statsigUser, setStatsigUser] = useState<StatsigUser | undefined>();
  /**
   * Function for setting custom attributes in the current statsig user.  This can be used to set custom attirbutes
   * based on current data from the page for more complicated feature gates/experiements (ex. gating based on the location_id
   * of a job the pro is currently clocking out of)
   */
  const setCustomAttributes = (
    customAttributes: Record<string, string | number | boolean | string[]>,
  ) => {
    setStatsigUser((prevStatsigUser) => ({
      ...prevStatsigUser,
      custom: {
        ...prevStatsigUser.custom,
        ...customAttributes,
      },
    }));
  };

  const { isLoading, currentUser } = useCurrentUserForStatsig();
  const config = useConfigurationContext();

  useEffect(() => {
    if (currentUser?.id) {
      setStatsigUser({
        userID: `account_${currentUser.id}`,
        email: currentUser.email,
        custom: {
          companyID: currentUser.company_id,
          isSaasPro: !!currentUser.company_id,
        },
        customIDs: {
          phase_id: `${currentUser?.professional?.split_pay_phase}`,
          professional_id: `${currentUser?.professional?.id}`,
        },
      });
    }
  }, [currentUser?.id]);

  if (!!window.Cypress) {
    (window as any).Statsig = Statsig;
  }

  return (
    <FeatureGateContext.Provider
      value={{
        isLoading,
        statsigUser,
        setCustomAttributes,
      }}
    >
      <StatsigProvider
        sdkKey={config.statsigSdkKey}
        user={statsigUser}
        waitForInitialization={false}
      >
        {children}
      </StatsigProvider>
    </FeatureGateContext.Provider>
  );
};

export const useFeatureGateContext = (): IContext => useContext(FeatureGateContext);
