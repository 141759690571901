import React from 'react';
import { Link, Text } from '@medely/ui-kit';
import { config } from '../../config';
import { HelpCenterLinkProps } from './interface';

export const HelpCenterLink = ({ configKey, url, text }: HelpCenterLinkProps) => {
  let finalURL = url ?? '';
  if (configKey) {
    finalURL = config.help[configKey];
  }

  return (
    <Text size="m">
      <Link url={finalURL} variant="text" size="m">
        {text}
      </Link>
    </Text>
  );
};
