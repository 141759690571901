import { MedelyDateTime } from '@medely/date-time-tools';
import { IPayout } from '@medely/types';
import { TextProps } from '@medely/ui-kit';

export enum PayoutDisplayStatus {
  Paid = 'Paid',
  Processing = 'Processing',
  Canceled = 'Canceled',
  Failed = 'Failed',
}

export const getPayoutDisplayStatus = ({
  status,
}: Pick<IPayout, 'status'>): PayoutDisplayStatus => {
  switch (status) {
    case 'paid':
      return PayoutDisplayStatus.Paid;
    case 'canceled':
      return PayoutDisplayStatus.Canceled;
    case 'failed':
      return PayoutDisplayStatus.Failed;
    default:
      // 'in_transit', 'pending', 'open', 'approved', 'hold', 'submitted'
      return PayoutDisplayStatus.Processing;
  }
};

export const getPayoutStatusColor = (status: PayoutDisplayStatus): TextProps['color'] => {
  switch (status) {
    case PayoutDisplayStatus.Paid:
      return 'state.success.secondary';
    case PayoutDisplayStatus.Canceled:
      return 'text.secondary';
    case PayoutDisplayStatus.Failed:
      return 'state.error.secondary';
    case PayoutDisplayStatus.Processing:
      return 'state.info.secondary';
  }
};

export const sumPayouts = (payouts: IPayout[]): number =>
  payouts.reduce((acc, payout) => {
    const isNotCanceled = payout.status !== 'canceled';
    const isPositiveAmount = payout.amount_cents > 0;

    if (isNotCanceled && isPositiveAmount) {
      return acc + payout.amount_cents;
    }
    return acc;
  }, 0);
export const getPayoutDateAsTitle = ({
  w2_submit_by_time,
  processed_on,
}: Pick<IPayout, 'processed_on' | 'w2_submit_by_time'>) => {
  const date = new MedelyDateTime(processed_on || w2_submit_by_time, { tz: 'system' });
  return date.format('MMMM d, yyyy');
};

export const isW2Payout = (payout: Pick<IPayout, 'kind'>): boolean => payout?.kind === 'w2';

export const isPaylocityPaystub = (payout: Pick<IPayout, 'starts_time'>): boolean => {
  const startsDate = new MedelyDateTime(payout.starts_time, { tz: 'system' });
  const initialPaylocityDate = new MedelyDateTime('2022-03-04', { tz: 'system' });

  return startsDate.isAfter(initialPaylocityDate);
};

const isPerDiemPayout = ({ assignment_id }: Pick<IPayout, 'assignment_id'>) => !assignment_id;
export const getPayPeriod = (
  payout: Pick<
    IPayout,
    'assignment_id' | 'ends_time' | 'starts_time' | 'processed_on' | 'w2_submit_by_time'
  >,
) => {
  const { ends_time, starts_time, processed_on, w2_submit_by_time } = payout;
  const isPerDiem = isPerDiemPayout(payout);

  let value = '-';
  if (isPerDiem) {
    value =
      processed_on || w2_submit_by_time
        ? new MedelyDateTime(processed_on || w2_submit_by_time, { tz: 'system' }).format('M/d/yy')
        : '-';
  } else if (starts_time && ends_time) {
    const startsDate = new MedelyDateTime(starts_time, { tz: 'system' });
    const endsDate = new MedelyDateTime(ends_time, { tz: 'system' });
    value = [startsDate.format('M/d/yy'), endsDate.format('M/d/yy')].join(' - ');
  }

  return {
    label: isPerDiem ? 'Issue date' : 'Pay period',
    value,
  };
};
