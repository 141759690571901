import { useState } from 'react';
import useGraphQLRequest from './useGraphQLRequest';
import { CHECK_USER_COORDINATES } from '@src/graphql';
import { useNetworkRequest } from './useNetworkRequest';

type CheckUserCoordinatesOptions = {
  jobId: number;
  latitude: number;
  longitude: number;
  action?: 'clock_in' | 'clock_out';
  silentClockInOut?: boolean;
};

export const useJobGeoLocation = () => {
  const [isCheckingUserCoordinates, setIsCheckingUserCoordinates] = useState(false);
  const { request } = useGraphQLRequest();
  const { axios } = useNetworkRequest();

  const postSilentClockIn = async ({
    action,
    latitude,
    longitude,
    jobId,
  }: {
    action: string;
    latitude: number;
    longitude: number;
    jobId: number;
  }) => {
    const attrs = {
      approved_clock_in: action === 'clock_in' ? new Date() : undefined,
      approved_clock_out: action === 'clock_out' ? new Date() : undefined,
      manual_override: false,
      lat: latitude,
      long: longitude,
    };

    await axios.post(`/v3/professional/jobs/${jobId}/${action}`, attrs);
  };

  const logClockInOut = async (jobId: number, action: 'clock_in' | 'clock_out'): Promise<void> => {
    const attrs = {
      approved_clock_in: action === 'clock_in' ? new Date() : undefined,
      approved_clock_out: action === 'clock_out' ? new Date() : undefined,
      manual_override: false,
      lat: 0,
      long: 0,
    };

    await axios.post(`/v3/professional/jobs/${jobId}/${action}`, attrs);
  };

  const checkUserCoordinates = async ({
    jobId,
    latitude,
    longitude,
    silentClockInOut = false,
    action,
  }: CheckUserCoordinatesOptions) => {
    try {
      setIsCheckingUserCoordinates(true);

      const { job } = await request(CHECK_USER_COORDINATES, {
        id: jobId,
        latitude,
        longitude,
      });

      const result = job.location.in_geo_fence_range;

      if (!result && silentClockInOut && action) {
        await postSilentClockIn({ action, latitude, longitude, jobId });
      }
      setIsCheckingUserCoordinates(false);
      return result;
    } catch {
      setIsCheckingUserCoordinates(false);
      return false;
    }
  };

  return { logClockInOut, isCheckingUserCoordinates, checkUserCoordinates };
};
