import { Box, Button, DatePicker } from '@medely/web-components';
import { EarningsHeaderProps } from './interface';
import { Heading, Label } from '@medely/ui-kit';
import { centsToCurrency } from '../../../utils/centsToCurrency';
import moment from 'moment';
import React from 'react';
import { UrlUpdateType } from 'use-query-params';
import useDevice from '../../../hooks/useDevice.web';

interface EarningsCalendarProps {
  month: Date;
  setMonth: (newDate: Date, updateType?: UrlUpdateType) => void;
}

const EarningsCalendar = ({ month, setMonth }: EarningsCalendarProps): React.ReactElement => {
  const onMonthChange = (date: moment.Moment | null) => {
    setMonth(moment(date).startOf('month').toDate());
  };

  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      gap={1}
      data-testid="earnings-calendar"
      pb={3}
    >
      <Box>
        <DatePicker
          fullWidth
          showYear
          variant="month"
          value={moment(month)}
          onChange={onMonthChange}
        />
      </Box>
      <Button
        variant="muted"
        squared
        onClick={() => onMonthChange(moment())}
        data-testid="earnings-calendar-today-button"
      >
        Today
      </Button>
    </Box>
  );
};

export const EarningsHeader = ({
  month,
  setMonth,
  amount,
}: EarningsHeaderProps): React.ReactElement => {
  const isMobile = useDevice() === 'mobile';

  return (
    <Box py={2} textAlign={isMobile ? 'center' : 'left'}>
      <EarningsCalendar month={month} setMonth={setMonth} />
      <Heading size="l" color="state.success.secondary" testId="total-monthly-payouts">
        {amount === 0 ? '$0' : centsToCurrency(amount)}
      </Heading>
      <Label size="l" color="text.secondary">
        Total monthly payouts
      </Label>
    </Box>
  );
};

export default EarningsHeader;
