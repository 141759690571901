import React from 'react';

import { ILocation, IState } from '@medely/types';
import { Link } from '../Link';
import DisplayHelpers from '../../utils/displayHelpers';

const { cityStateZip } = DisplayHelpers.location;

type AddressLinkProps = {
  location: Pick<ILocation, 'address_first' | 'address_second' | 'address_city' | 'address_zip'> & {
    state: Pick<IState, 'abbreviation'>;
  };
};

export const AddressLink = ({ location }: AddressLinkProps) => {
  const addressSecondString = location.address_second ? `${location.address_second}, ` : '';
  return (
    <Link
      url={`https://maps.google.com/?q=${location.address_first}, ${
        location.address_second ?? ''
      } ${cityStateZip(location)}`}
      variant="text"
      size="m"
    >
      {`${location.address_first}, ${addressSecondString}${cityStateZip(location)}`}
    </Link>
  );
};
