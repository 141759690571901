import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import filter from 'lodash/filter';
import { useNetworkRequest } from './useNetworkRequest';

export type UseTermsOfServiceOptions = {
  accessId: string;
  signerId: string;
  groupKey: string;
  enabled?: boolean;
};

export function useTermsOfService(options: UseTermsOfServiceOptions) {
  const { unsignedContractCount, isLoading, isSuccess, refetch } =
    useUnsignedContractCount(options);

  const hasSignedLatestTerms = unsignedContractCount === 0;

  return {
    hasSignedLatestTerms,
    unsignedContractCount,
    isLoading,
    isSuccess,
    refetch,
  };
}

export function useUnsignedContractCount(options: UseTermsOfServiceOptions) {
  const { accessId, groupKey, signerId, enabled } = options;
  const { axios } = useNetworkRequest();

  const checkContracts = async () => {
    const { data } = await axios.get(
      `https://pactsafe.io/latest?sid=${accessId}&gkey=${groupKey}&sig=${encodeURIComponent(
        signerId,
      )}`,
    );
    const numberOfUnsignedContracts = filter(data, (contract: unknown) => !contract).length;
    return numberOfUnsignedContracts;
  };

  const {
    data: unsignedContractCount,
    isLoading,
    isInitialLoading,
    isSuccess,
    refetch,
  } = useQuery({
    enabled,
    queryKey: ['termsOfService', accessId, signerId, groupKey],
    queryFn: async () => {
      const numberOfUnsignedContracts = await checkContracts();
      return numberOfUnsignedContracts;
    },
  });

  return {
    unsignedContractCount,
    isLoading: enabled ? isLoading : isInitialLoading,
    isSuccess,
    refetch,
  };
}

export type UseSignContractOptions = {
  accessId: string;
  signerId: string;
};

type AgreementParams = {
  groupId: string;
  versionIds: string[];
};

export function useSendContractAgreement(options: UseSignContractOptions) {
  const { accessId, signerId } = options;
  const { axios } = useNetworkRequest();
  const queryClient = useQueryClient();

  const sendActivity = async ({ groupId, versionIds }: AgreementParams) => {
    const sig = encodeURIComponent(signerId);
    const vid = versionIds.join(',');
    return axios.post(
      `https://pactsafe.io/send?sid=${accessId}&gid=${groupId}&sig=${sig}&et=agreed&vid=${vid}`,
    );
  };

  const {
    mutate: sendAgreement,
    mutateAsync: sendAgreementAsync,
    isLoading,
    isError,
  } = useMutation(sendActivity, {
    onSuccess: () => {
      // Refetch all terms of service queries after new agreement
      queryClient.invalidateQueries({
        queryKey: ['termsOfService'],
        exact: false,
      });
    },
  });

  return {
    sendAgreement,
    sendAgreementAsync,
    isLoading,
    isError,
  };
}
