import config from '@src/config';
import useCurrentUser from 'hooks/useCurrentUser';
import useProfessionalQualifications from 'hooks/useProfessionalQualifications';
import { isProduction } from '@src/utils/env';
import { createPopup } from '@typeform/embed';
import { useEffect, useRef } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { axios } from 'utils/networkRequests';
import '@typeform/embed/build/css/popup.css';

const TypeFormWebView = () => {
  useTypeFormWebView();

  return null;
};

const useTypeFormWebView = () => {
  const { currentUser } = useCurrentUser();
  const { submitPendingProfessionalQualification } = useProfessionalQualifications();

  const [{ formId, professionalQualificationId }] = useQueryParams({
    formId: StringParam,
    professionalQualificationId: NumberParam,
  });

  const openTypeForm = (formId: string, professionalQualificationId?: number) => {
    createPopup(formId, {
      hidden: {
        professional_id: currentUser.professional?.id.toString(),
        environment: isProduction() ? 'production' : 'staging',
      },
      onReady() {
        if (professionalQualificationId) {
          submitPendingProfessionalQualification({
            qualification_id: professionalQualificationId,
            professional_id: currentUser.professional?.id,
          });
        }
      },
      onClose() {
        window.ReactNativeWebView.postMessage('typeform_close');
      },
      onSubmit: async () => {
        if (formId === config.accountPausedTypeformId) {
          try {
            await axios.post(`/v3/professional/paused_status_appeal`);
            setTimeout(() => {
              window.ReactNativeWebView.postMessage('typeform_submitted');
            }, 1000);
          } catch {
            window.ReactNativeWebView.postMessage('typeform_error');
          }
        } else {
          setTimeout(() => {
            window.ReactNativeWebView.postMessage('typeform_submitted');
          }, 1000);
        }
      },
    }).toggle();
  };

  const hasOpened = useRef(false);

  useEffect(() => {
    if (formId && currentUser && !hasOpened.current) {
      openTypeForm(formId, professionalQualificationId);
      hasOpened.current = true;
    }
  }, [currentUser, formId]);
};

export default TypeFormWebView;
