import moment from 'moment';

type RenderClockOutPastScheduleProps = {
  now: string | Date;
  expectedEndTime: string | Date;
  disabledEditClockOutTime: boolean;
};

const renderClockOutPastSchedule = ({
  now,
  expectedEndTime,
  disabledEditClockOutTime,
}: RenderClockOutPastScheduleProps) => {
  const fiveMinutesGraced = moment(expectedEndTime).add(5, 'minutes');
  const sixMinutesFromEndTime = moment(expectedEndTime).add(6, 'minutes');
  const currentTime = moment(now);

  if (disabledEditClockOutTime) {
    return expectedEndTime;
  }

  const isWithinGracePeriod =
    fiveMinutesGraced.isSameOrAfter(currentTime) && currentTime.isBefore(sixMinutesFromEndTime);

  return isWithinGracePeriod ? now : expectedEndTime;
};

export const ClockInOutDisplayHelpers = {
  renderClockOutPastSchedule,
};
