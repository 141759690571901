import React, { useEffect } from 'react';
import { Box, Form, Heading, SubmitButton, Text, useAnalytics } from '@medely/ui-kit';
import { WorkExperienceForm } from '../WorkExperienceForm';
import { useGetWorkExperienceInitialData } from '../useGetWorkExperienceInitialData';
import { LoadingState } from '../../../components/LoadingState';
import { useWorkExperience } from '../useWorkExperience';
import useWorkExperienceInfoMutations from '../../../../../hooks/useWorkExperienceInfoMutations';
import { createMostRecentWorkExperienceValidation } from '../workExperienceSchema';
import { ValuesProps } from '../types';

type AddExperienceModalInfoProps = {
  onClose: () => void;
};

export const AddExperienceModalInfo = ({ onClose }: AddExperienceModalInfoProps) => {
  const analytics = useAnalytics();
  const { currentUser, currentUserLoading, locationTypesLoading } =
    useGetWorkExperienceInitialData();
  const { createPayload, totalYearsOfExperience } = useWorkExperience({
    currentUser: currentUser || undefined,
  });
  const { submitWorkExperienceInfo } = useWorkExperienceInfoMutations();

  const baseValuesLoading = currentUserLoading || locationTypesLoading;

  const handleSubmit = (values: ValuesProps) => {
    const parsedValues = createPayload(values);
    submitWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    analytics.track('Product Application Add Secondary Experience  - Viewed', {
      account_id: currentUser?.id,
      context: 'Product Application Add Secondary Experience',
      subcontext: null,
      action: 'Viewed',
    });
  }, []);

  if (baseValuesLoading) {
    return <LoadingState />;
  }

  return (
    <Form
      handleSubmit={handleSubmit}
      schema={createMostRecentWorkExperienceValidation(totalYearsOfExperience)}
    >
      <Box pb={2}>
        <Heading size="s" testId="add-experience-modal-title">
          Add experience
        </Heading>
        <Text size="m" testId="add-experience-modal-subtitle">
          Provide details for a recent position in the U.S.
        </Text>
      </Box>
      <WorkExperienceForm
        currentUser={currentUser || undefined}
        isExtraInformation={true}
        hideButtonToSummary={true}
      />
      <SubmitButton fullWidth testId="add-experience-modal-save-button">
        Save
      </SubmitButton>
    </Form>
  );
};
