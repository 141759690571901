import {
  Box,
  Button,
  CheckboxField,
  CurrencyField,
  DateField,
  HStack,
  Label,
  SingleSelectField,
  ToggleButtonGroupField,
  useAnalytics,
  VStack,
} from '@medely/ui-kit';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { EMPLOYMENT_TYPE_OPTIONS, PATIENT_CARE_OPTIONS } from './constants';
import { SetPageProps } from './types';
import { FragmentType, graphql, parseFragmentData } from '../../../../graphql/generated';
import { useGetWorkExperienceInitialData } from './useGetWorkExperienceInitialData';
import { useWorkExperience } from './useWorkExperience';
import { useWageLog } from './useWageLog';
import isNil from 'lodash/isNil';

const WorkExperienceFormCurrentUserGql = graphql(`
  fragment WorkExperienceFormCurrentUser on Account {
    id
    address_zip
    professional {
      markets {
        id
        market_locations {
          zip_code
        }
      }
      professional_positions {
        years_of_experience
        position {
          id
        }
      }
    }
  }
`);

type WorkExperienceFormProps = {
  currentUser?: FragmentType<typeof WorkExperienceFormCurrentUserGql>;
  hidePositionField?: boolean;
  positionId?: number;
  hideButtonToSummary?: boolean;
  isExtraInformation?: boolean;
  setPage?: (value: SetPageProps) => void;
  showWageLog?: boolean;
};

export const WorkExperienceForm = ({
  currentUser: inputCurrentUser,
  hidePositionField = false,
  positionId,
  isExtraInformation = false,
  hideButtonToSummary = false,
  setPage,
  showWageLog = false,
}: WorkExperienceFormProps) => {
  const { locationTypeOptions } = useGetWorkExperienceInitialData();
  const currentUser = parseFragmentData(WorkExperienceFormCurrentUserGql, inputCurrentUser);
  const analytics = useAnalytics();
  // @ts-ignore
  const { positionsOptions } = useWorkExperience({ currentUser });
  const selectedPositionId = useWatch({ name: 'position_id' });
  const currentlyWorkHere = useWatch({ name: 'currently_work_here' });

  const parsedPositionId = positionId ?? selectedPositionId;
  const zipCode = currentUser?.address_zip;

  const getMarketIdFromZip = () => {
    const allMarkets = currentUser?.professional?.markets ?? [];
    const marketsWithZip =
      allMarkets.filter((market) =>
        market.market_locations?.find((market_location) => market_location.zip_code === zipCode),
      ) ?? [];

    return !!marketsWithZip.length
      ? marketsWithZip[0].id
      : currentUser?.professional?.markets?.[0].id;
  };

  const { hasProfessionalRatesData, wageLogHelperText } = useWageLog({
    market_id: getMarketIdFromZip(),
    position_id: parsedPositionId,
  });

  const selectedPositionData = currentUser?.professional?.professional_positions?.find(
    (p: { position: { id: number } }) => p.position.id === parsedPositionId,
  );

  const selectedPositionDataYoE = selectedPositionData?.years_of_experience;
  const startDateHelperText =
    !isNil(selectedPositionDataYoE) && selectedPositionDataYoE <= 1
      ? 'Exclude new hire training period'
      : undefined;

  const showWageLogField = isExtraInformation || hasProfessionalRatesData || showWageLog;

  const handleGoToSummaryClick = () => {
    analytics.track(
      'Product Application - Primary Experience Required Experience Button - Clicked',
      {
        account_id: currentUser?.id,
        context: 'Product Application Primary Experience',
        subcontext: 'Required Experience Button',
        action: 'Clicked',
      },
    );
    if (setPage) {
      setPage('experienceSummary');
    }
  };

  return (
    <>
      {!hidePositionField && (
        <>
          <Box testId="work-experience-form-position-id">
            <SingleSelectField
              label={isExtraInformation ? 'Position' : 'Most recent position'}
              name="position_id"
              options={positionsOptions}
              placeholder="Select position"
            />
          </Box>

          <Box py={1} />
        </>
      )}

      {(selectedPositionId || hidePositionField) && (
        <>
          <Box testId="work-experience-form-bedside-care">
            <ToggleButtonGroupField
              label="Did you provide direct patient care?"
              name="bedside_care"
              options={PATIENT_CARE_OPTIONS}
            />
          </Box>
          <Box py={1} />
          <Box testId="work-experience-form-location-type-id">
            <SingleSelectField
              label="Facility type"
              name="location_type_id"
              options={locationTypeOptions}
              placeholder=""
            />
          </Box>

          <Box py={1} />
          {showWageLogField && (
            <>
              <Box testId="work-experience-form-hourly-rate-cents">
                <Label size="s">Hourly rate (optional)</Label>
                <CurrencyField name="hourly_rate_cents" helperText={wageLogHelperText} />
              </Box>
              <Box py={1} />
            </>
          )}
          <Box testId="work-experience-form-employment-type">
            <SingleSelectField
              label="Employment type"
              name="employment_type"
              options={EMPLOYMENT_TYPE_OPTIONS}
              placeholder=""
            />
          </Box>
          <Box py={1} />
          <Box testId="work-experience-form-currently-work-here">
            <CheckboxField
              label="I’m currently working in this position"
              name="currently_work_here"
            />
          </Box>
          <HStack>
            <VStack
              mr={currentlyWorkHere ? 0 : 1}
              width="100%"
              testId="work-experience-form-start-date"
            >
              <Label size="s">Start date</Label>
              <DateField name="start_date" helperText={startDateHelperText} />
            </VStack>
            {!currentlyWorkHere && (
              <VStack mr={1} width="100%" testId="work-experience-form-end-date">
                <Label size="s">End date</Label>
                <DateField name="end_date" />
              </VStack>
            )}
          </HStack>
          {!hideButtonToSummary && (
            <HStack py={1} justifyContent="center" alignItems="center">
              <Button
                variant="text"
                onClick={() => handleGoToSummaryClick()}
                testId="work-experience-form-go-to-summary-button"
              >
                I don’t have the required experience
              </Button>
            </HStack>
          )}
          <Box py={1} />
        </>
      )}
    </>
  );
};
