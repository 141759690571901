import { EarningsJobListItemProps } from '../interface';
import { PayoutDetailsLayoutProps } from './interface';

type JobReturnType = EarningsJobListItemProps['job'] & { id: number };
export const formatPayoutForJobList = (payout: PayoutDetailsLayoutProps['payout']) => {
  const allAdj = payout.expense_adjustments ?? [];
  const allJb = payout.job_billings ?? [];

  const jobs: { [id: number]: JobReturnType } = {};

  allAdj.forEach((adj) => {
    if (!adj.job_id) {
      return;
    }

    const formattedAdj = {
      amount_cents: adj.amount_cents,
      payout: {
        status: payout.status,
      },
    };
    const existingJob = jobs[adj.job_id];
    if (existingJob) {
      existingJob.expense_adjustments.push(formattedAdj);
    } else if (adj.job) {
      jobs[adj.job_id] = {
        id: adj.job_id,
        ...adj.job,
        expense_adjustments: [formattedAdj],
        job_billings: [],
      };
    }
  });

  allJb.forEach((jb) => {
    const formattedJb = {
      id: jb.id,
      category: jb.category,
      payout_total_amount_cents: jb.payout_total_amount_cents,
      payout_weekly_overtime_amount_cents: jb.payout_weekly_overtime_amount_cents,
      total_hours: jb.total_hours,
      payout: {
        status: payout.status,
      },
    };

    const existingJob = jobs[jb.job_id];
    if (existingJob) {
      existingJob.job_billings.push(formattedJb);
    } else if (jb.job) {
      jobs[jb.job_id] = {
        id: jb.job_id,
        ...jb.job,
        job_billings: [formattedJb],
        expense_adjustments: [],
      };
    }
  });

  return Object.values(jobs);
};
