export const RELEASE_STAGES = ['production', 'pre-production', 'development'] as const;
export type ReleaseStages = (typeof RELEASE_STAGES)[number];

export function isProduction() {
  return process.env.REACT_APP_RELEASE_STAGE === 'production';
}

export function isPreProduction() {
  return process.env.REACT_APP_RELEASE_STAGE === 'pre-production';
}

export function isDevelopment() {
  // Development is the default value if not production or pre-production
  // This includes process.env.REACT_APP_RELEASE_STAGE being undefined
  return !isProduction() && !isPreProduction();
}
